import { AccountSummaryState } from './../../shared/store-utilities/state/accountSum.state';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import filter from 'lodash/filter';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import { Select } from '@ngxs/store';
import { DateUtil } from '../utility-classes/date.util';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'dddd, DD MMM YY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Injectable({
  providedIn: 'root',
})
export class TransactionListService {
  @Select(AccountSummaryState.getSelectedAccountSum)
  selectedAccountSummary$: Observable<any>;
  sortTransactionHistory(transactionHistory, isReverseRequired) {
    const TransactionDateArray = [];
    const sortedTransactionHistory = [];
    let SortedTransactionDateArray = [];

    transactionHistory = map(transactionHistory, (transValue: any) => {
      const transDate = DateUtil.format(transValue.transactionDate, DateUtil.ISO_YYYY_MM_DD);
      transValue = { ...transValue, ...{ date: transDate } };
      transValue.transDetails = {
        amount: transValue.amount ? transValue.amount.amount : 0,
        balance: transValue.runningBalance ? transValue.runningBalance.amount : 0,
        description: transValue.narrative ? transValue.narrative : '',
      };

      if (!includes(TransactionDateArray, transDate)) {
        TransactionDateArray.push(transDate);
      }

      if (isReverseRequired) {
        SortedTransactionDateArray = TransactionDateArray.reverse();
      } else {
        SortedTransactionDateArray = TransactionDateArray;
      }
      return transValue;
    });

    forEach(SortedTransactionDateArray, (valDate: any) => {
      const filterArrayOfDateWiseTxs = filter(transactionHistory, {
        date: valDate,
      });
      if (filterArrayOfDateWiseTxs.length) {
        const finalTxn = {
          date: valDate,
          TransDetails: map(filterArrayOfDateWiseTxs, 'transDetails'),
        };
        sortedTransactionHistory.push(finalTxn);
      }
    });

    return sortedTransactionHistory;
  }
  getSelectedAccountData(selectedAccount) {
    let fullAccountData = [];
    this.selectedAccountSummary$.subscribe((accountSumData) => {
      if (accountSumData) {
        fullAccountData = accountSumData;
      }
    });
    let selectedAccountDetails;
    for (let i = fullAccountData.length - 1; i >= 0; i--) {
      if (fullAccountData[i].number === selectedAccount.account.number) {
        selectedAccountDetails = fullAccountData[i];
      }
    }
    return selectedAccountDetails;
  }
}
