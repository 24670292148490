import { Component, Input } from '@angular/core';

import { NameInitialsService } from '../../../core/name-initials/name-initials.service';

@Component({
  selector: 'sbg-name-initials',
  templateUrl: './name-initials.component.html',
  styleUrls: ['./name-initials.component.scss'],
})
export class NameInitialsComponent {
  @Input() benefName: string;
  @Input() type: string;
  @Input() selectType: string;

  constructor(private nameInitialsService: NameInitialsService) {}

  getNameInitials(): string {
    if (this.benefName) {
      return this.nameInitialsService.getInitials(this.benefName);
    }
  }
}
