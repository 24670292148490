import { createAction, props } from '@ngrx/store';
import { Beneficiary } from '../../../core/recipient-service/data/beneficiary';
import { RecipientGroup } from '../../../core/recipient-service/data/recipient-group';

// beneficiary
export const setBeneficiaryRecipientData = createAction(
  '[RecipientList Component] setBeneficiaryRecipient',
  props<{ beneficiaries: Beneficiary[] }>()
);

export const setBeneficiaryGroupData = createAction(
  '[RecipientList Component] setBeneficiaryGroupData',
  props<{ beneficiaryGroups: RecipientGroup[] }>()
);

export const setSelectedBeneficiaryGroupData = createAction(
  '[RecipientList Component] setSelectedBeneficiaryGroupData',
  props<{ beneficiaryGroupDetails: RecipientGroup }>()
);

export const setAddBeneficiaryData = createAction(
  '[RecipientList Component] setAddBeneficiaryData',
  props<{ addBeneficiary: {} }>()
);

export const setSelectedBeneficiaryData = createAction(
  '[RecipientList Component] setSelectedBeneficiaryData',
  props<{ selectedBeneficiary: {} }>()
);

// billers

export const setBillerRecipientData = createAction(
  '[RecipientList Component] setBillerRecipient',
  props<{ billers: Array<{}> }>()
);
export const setAllBillerRecipientData = createAction(
  '[RecipientList Component] setAllBillerRecipient',
  props<{ allBillers: any }>()
);

export const setBillerGroupData = createAction(
  '[RecipientList Component] setBillerGroupData',
  props<{ billerGroups: Array<{}> }>()
);

export const setSelectedBillerGroupData = createAction(
  '[RecipientList Component] setSelectedBillerGroupData',
  props<{ billerGroupDetails: {} }>()
);

export const setAddBillerData = createAction(
  '[RecipientList Component] setAddBillerData',
  props<{ addBiller: {} }>()
);

export const setSelectedBillerData = createAction(
  '[RecipientList Component] setSelectedBillerData',
  props<{ selectedBiller: {} }>()
);

// active tab

export const setActiveTabData = createAction(
  '[RecipientList Component] setActiveTabData',
  props<{ activeTab: {} }>()
);

// CMA-beneficiary
export const setCmaBeneficiaryRecipientData = createAction(
  '[RecipientList Component] setCmaBeneficiaryRecipient',
  props<{ cmaBeneficiaries: Beneficiary[] }>()
);

export const setCmaBeneficiaryGroupData = createAction(
  '[RecipientList Component] setCmaBeneficiaryGroupData',
  props<{ cmaBeneficiaryGroups: RecipientGroup[] }>()
);

export const setSelectedCmaGroup = createAction(
  '[RecipientList Component] setSelectedCmaGroup',
  props<{ cmaGroup: RecipientGroup }>()
);

export const setAddCMABeneficiaryData = createAction(
  '[RecipientList Component] setAddCMABeneficiaryData',
  props<{ addCMABeneficiary: {} }>()
);

export const setSelectedCmaBeneficiaryData = createAction(
  '[RecipientList Component] setSelectedCmaBeneficiaryData',
  props<{ selectedCmaBeneficiary: Beneficiary }>()
);

//Mobile-wallet-beneficiary

export const setMobileWalletBeneficiaryRecipientData = createAction(
  '[RecipientList Component] setMobileWalletBeneficiaryRecipient',
  props<{ MobileWalletBeneficiaries: Beneficiary[] }>()
);

export const setAddMobileWalletBeneficiaryData = createAction(
  '[RecipientList Component] setAddMobileWalletBeneficiaryData',
  props<{ addMobileWalletBeneficiary: {} }>()
);

export const setSelectedMobileWalletBeneficiaryData = createAction(
  '[RecipientList Component] setSelectedMobileWalletBeneficiaryData',
  props<{ selectedMobileWalletBeneficiary: {} }>()
);
