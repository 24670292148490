import { RouterStateService } from './../../services/route-state/route-state.service';
import { DataSharingService } from './../data-sharing/data-sharing.service';
import { OtpDetails } from './../../shared/store-utilities/actions/otp.action';
import { Store } from '@ngxs/store';
import { SessionStore } from './../../services/SessionStore/SessionStore';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import includes from 'lodash/includes';

import { TokenService } from '../token/token.service';
import { McaHeader } from '../data/mca-header';
import { McaResponseType } from '../data/mca-response-type';
import { setStampedStatementPayload } from './../../shared/store-utilities/actions/banking-metadata.action';
import { Store as ngrxjs } from '@ngrx/store';
import { SignOutService } from '../../core/sign-out/sign-out.service';
import { SnackBarService } from '../../services/snack-bar-service/snackBarService';
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  cancelReset;
  previousUrl: string;
  isHybrid: boolean;
  constructor(
    private tokenService: TokenService,
    private sessionStore: SessionStore,
    private router: Router,
    private routerState: RouterStateService,
    private store: Store,
    private dataSharing: DataSharingService,
    private ngRx: ngrxjs<any>,
    public signOutService: SignOutService,
    private snack: SnackBarService
  ) {
    this.ngRx.select('appReducer', 'loginReducer').subscribe((value) => {
      if (Object.prototype.hasOwnProperty.call(value, 'isMobileActive')) {
        this.isHybrid = value.isMobileActive.isHybrid;
      }
    });
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url === '/roa-mobile/rest/AccountService/Statement' && req.body.statementType !== 'PROVISIONAL') {
      this.ngRx.dispatch(setStampedStatementPayload({ stampedStatementPayload: req.body }));
    }
    let dupReq;
    const sessionid = this.sessionStore.getItem('SessionID');

    if (
      includes(req.url, 'UploadDocument') ||
      includes(req.url, 'file-upload') ||
      includes(req.url, 'UploadInternationalPaymentDocument')
    ) {
      dupReq = req;
    } else {
      dupReq = req.clone({
        setHeaders: { 'Content-Type': 'application/json;charset=UTF-8' },
      });
      dupReq = req.clone({
        setHeaders: { Accept: '*/*' },
      });
    }
    const token = this.tokenService.getToken();

    if (token) {
      dupReq = dupReq.clone({ setHeaders: { 'x-sbg-token': token } });
    }

    if (sessionid) {
      dupReq = dupReq.clone({
        setHeaders: { 'x-sbg-session-id': sessionid.SmartText },
      });
      dupReq = dupReq.clone({
        setHeaders: { 'x-sbg-ui-page-url': window.location.href },
      });
    }
    if (this.isHybrid) {
      dupReq = dupReq.clone({
        setHeaders: { 'x-sbg-channel': 'SBG_MOBILE' },
      });
    } else {
      dupReq = dupReq.clone({
        setHeaders: { 'x-sbg-channel': 'SBG_WEB' },
      });
    }
    if (window['loginBeforeTimeout']) {
      dupReq = dupReq.clone({
        setHeaders: {
          'x-sbg-login-snippet': String(window['loginBeforeTimeout']),
        },
      });
    }
    if (window['loginAfterTimeout']) {
      dupReq = dupReq.clone({
        setHeaders: {
          'x-sbg-login-timeout': String(window['loginAfterTimeout']),
        },
      });
    }
    return next.handle(dupReq).pipe(
      switchMap((event: HttpEvent<any>) => {
        if (
          event instanceof HttpResponse &&
          event.headers.get(McaHeader.X_SBG_RESPONSE_TYPE) === McaResponseType.STEPUP
        ) {
          this.cancelReset = event.url.includes('SecurityService/ResendStepUp');
          if (!this.cancelReset && this.routerState.getCurrentUrl() !== '/otp') {
            const newBody = {
              event: { ...event },
              ...req,
              stepUp: { ...event.body.stepUp },
            };
            this.store.dispatch(new OtpDetails(newBody));
            this.router.navigate(['./otp']);
            return [];
          } else {
            return of(event);
          }
        } else if (
          event instanceof HttpResponse &&
          event.headers.get(McaHeader.X_SBG_RESPONSE_TYPE) === McaResponseType.ERROR
        ) {
          if (this.maskErrorScreen(event)) {
            const errorReason = event.headers.get('x-sbg-state');
            const errorMessage = event.headers.get(McaHeader.X_SBG_RESPONSE_MESSAGE);
            this.dataSharing.setErrorMessage(errorMessage);
            this.dataSharing.setErrorReason(errorReason);
            let currentUrl = this.routerState.getCurrentUrl();
            if (!includes(event.url, 'AuthenticateDI')) {
              if (includes(event.url, 'Statement') || currentUrl === '/otp') {
                if (errorReason === 'FORCED_SESSION_TERMINATION') {
                  this.snack.open(errorMessage, 'error', 4000);
                  this.signOutService.signout();
                }
              } else {
                this.dataSharing.setErrorModal(true);
              }
              return [];
            } else {
              return of(event);
            }
          } else {
            return of(event);
          }
        } else {
          if (!this.cancelReset && this.routerState.getCurrentUrl() !== '/otp') {
            const newBody = {
              ...event,
            };
            this.store.dispatch(new OtpDetails(newBody));
          }
          return of(event);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        let errorMessage;
        if (error.url.includes('/AccountService/Accounts')) {
          errorMessage =
            'Apologies! Kindly note that accounts, account balance information and financial services are currently unavailable. ' +
            'Please proceed to make use of the non-financial services available in Menu option while we rectify the problem. ' +
            'Thank you for your patience';
          this.dataSharing.setMaskFinancialFlows(true);
        } else if (error.url.includes('/TransactionService/Transaction')) {
          errorMessage =
            error.headers.get(McaHeader.X_SBG_RESPONSE_MESSAGE) ||
            'ALERT! We have detected a technical problem.  Kindly check your account BEFORE attempting to make the transaction again.';
        } else {
          console.log(error.headers.get('x-sbg-state'));
          errorMessage =
            error.headers.get(McaHeader.X_SBG_RESPONSE_MESSAGE) ||
            'Apologies.  We are experiencing a technical problem.  Please try again shortly, while we rectify the issue';
        }
        this.dataSharing.setErrorMessage(errorMessage);
        if (
          (error && error.error && !(error.error.recordsCount === 5 || error.error.recordsCount === 7)) ||
          (!error.error &&
            (error.status === 400 || error.status.toString().startsWith('5') || error.status === 404))
        ) {
          this.dataSharing.setErrorModal(true);
        }
        return throwError(errorMessage);
      })
    );
  }

  maskErrorScreen(event): boolean {
    // special caser for otp

    // let statusResult =
    //   event.status === 204 && event.headers.get(McaHeader.X_SBG_RESPONSE_TYPE) === McaResponseType.ERROR;
    // let currentUrl = this.routerState.getCurrentUrl();
    if (includes(event.url, 'file-upload') || includes(event.url, 'UploadInternationalPaymentDocument')) {
      if (event.status === 204) {
        return false;
      }
      // } else if (currentUrl === '/otp') {
      //   if (statusResult) {
      //     return true;
      //   }
    }
    return true;
  }
}
