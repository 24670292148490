<ng-container *ngIf="!isHybrid">
  <div class="generic-error-modal" *ngIf="errorOccured">
    <div class="error-heading banklogo3d"></div>
    <div class="error-page">
      <img alt="error_module" class="error-module-img" src="/assets/images/error.png" />
      <div class="error-icon"><img src="" alt="" /></div>
      <div class="an-error-has-occurred">An error has occurred</div>
      <div class="error-description">Error description</div>
      <div class="error-detail-info">
        {{ errorMessage }}
      </div>
      <div class="continue" *ngIf="errorReason !== 'FORCED_SESSION_TERMINATION'">
        <sbg-button
          class="details-footer__next"
          [isRaisedButton]="true"
          buttonType="primary"
          [disableRipple]="false"
          buttonStyle="basic"
          buttonText="Continue"
          ariaLabel="Review details"
          (clickFunction)="closeErrorModal()"
        ></sbg-button>
      </div>
      <div class="continue" *ngIf="errorReason === 'FORCED_SESSION_TERMINATION'">
        <sbg-button
          class="details-footer__next"
          [isRaisedButton]="true"
          buttonType="primary"
          [disableRipple]="false"
          buttonStyle="basic"
          buttonText="Sign Out"
          ariaLabel="Sign Out"
          (clickFunction)="signOut()"
        ></sbg-button>
      </div>
      <div class="need-help">
        <span><a class="need-help">Need help? </a></span>
        <span> Call us on: {{ contactNumber }} or </span>
        <span
          ><a class="login-content__footer--link remove-link-underline email-us" href="mailto:{{ mailId }}"
            >Email us</a
          ></span
        >
      </div>
    </div>
  </div>
</ng-container>
<!-- ****************************************hybrid app************************************ -->
<ng-container *ngIf="isHybrid">
  <section class="hybridErorBackdrop" *ngIf="errorOccured">
    <sbg-native-header [nextButtonDisable]="false" [isDashboardBackbutton]="true"></sbg-native-header>
    <section class="hybrid-card-container">
      <div class="align-content-center">
        <div *ngIf="!isdashboardError">
          <mat-icon class="iconBig mb-40" svgIcon="icn_alert_circle_outline" *ngIf="!isWarning"></mat-icon>
          <mat-icon class="warningIcon mb-40" svgIcon="icn_warning_outline" *ngIf="isWarning"></mat-icon>
        </div>
        <div *ngIf="isdashboardError" class="dashboard_border">
          <mat-icon class="iconBig mb-40 dashboad_refresh-icon" svgIcon="icn_reload"></mat-icon>
        </div>
      </div>
      <div class="">
        <div class="align-content-center--font padding-0 mr-24 ml-24">
          <span *ngIf="isdashboardError">
            This service is unavailable at the moment. Please close the application and restart.
          </span>
          <span *ngIf="!isdashboardError">{{ errorMessage }}</span>
        </div>
        <div class="align-content-center mt-32" *ngIf="!isdashboardError">
          <sbg-hybrid-button
            *ngIf="errorReason !== 'FORCED_SESSION_TERMINATION'"
            class="details-footer__next"
            [isRaisedButton]="true"
            buttonType="primary"
            [disableRipple]="false"
            buttonStyle="hybridError"
            [buttonText]="isWarning ? 'BACK' : 'DONE'"
            ariaLabel="Review details"
            (clickFunction)="closeErrorModal()"
          ></sbg-hybrid-button>
          <sbg-hybrid-button
            *ngIf="errorReason === 'FORCED_SESSION_TERMINATION'"
            class="details-footer__next"
            [isRaisedButton]="true"
            buttonType="primary"
            [disableRipple]="false"
            buttonStyle="hybridError"
            [buttonText]="'Sign Out'"
            ariaLabel="Review details"
            (clickFunction)="signOut()"
          ></sbg-hybrid-button>
        </div>
      </div>
    </section>
    <div class="hyb-error-footer">
      <hr class="light-solid" />
      <span class="">
        <mat-icon class="hybrid-icon-right pr-8" svgIcon="icn_phone" [inline]="true"></mat-icon
      ></span>
      <span class="customer__details--name pr-8">Need help?</span>
      <a *ngIf="!isMultiContactNumber" class="hybrid_country_codes-text" href="tel:{{ contactNumber }}">{{
        contactNumber
      }}</a>
      <span *ngIf="isMultiContactNumber">
        <a
          *ngFor="let number of hybNumbers; last as isLast"
          class="hybrid_country_codes-text"
          href="tel:{{ number }}"
          >{{ number }}
          <span *ngIf="!isLast"> / </span>
        </a>
      </span>
      <br />
      <a class="hybrid_country_codes-text" href="mailto:{{ mailId }}"> Email us </a>
    </div>
  </section>
</ng-container>
