<div class="flex-container align-center">
  <div *ngIf="!(type === 'list' || type === 'add')" class="name-initials name-initials__input-field">
    <mat-icon *ngIf="!benefName" matPrefix class="material-icons font-primary mr-16"> person </mat-icon>

    <!-- <i class="material-icons" *ngIf="!benefName">person</i> -->
    <span *ngIf="benefName">{{ getNameInitials() }}</span>
  </div>
</div>
<div
  *ngIf="type === 'list' || type === 'add'"
  class="name-initials__input-field"
  [ngClass]="{
    'name-initials--list': type === 'list' || type === 'add',
    'name-initials--add': type === 'add'
  }"
>
  <!-- <i class="material-icons" [hidden]="benefName || type == 'add'">person</i> -->
  <span *ngIf="!benefName">{{ getNameInitials() }}</span>
  <span *ngIf="type === 'add'">
    <mat-icon class="sidenav__footer--list-icon">add</mat-icon>
  </span>
</div>
