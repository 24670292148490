<ng-container *ngIf="!isHybrid">
  <section class="display__approver__container" *ngIf="selectedApprover">
    <div [ngClass]="config.countryName === 'Tanzania' ? 'display__approver__details no-border' : 'display__approver__details'">
      <p class="other-details__field-heading">Approvers</p>
      <p class="other-details__field-value">{{ selectedApprover.description }}</p>
      <p class="other-details__field-value">
        {{ selectedApprover.hierarchyEnabled ? 'With hierarchy' : 'Without hierarchy' }}
      </p>
    </div>
    <div class="display__approver__details mt-10" *ngIf="selectedApprover.remarks || config.countryName === 'Tanzania'">
      <p class="other-details__field-heading">{{config.countryName === 'Tanzania' ? 'Notes to Approvers' : 'Notes' }}</p>
      <p class="other-details__field-value">{{ selectedApprover.remarks ? selectedApprover.remarks : '-- --' }}</p>
    </div>
  </section>
</ng-container>
<!-- ****************************************************************Hybrid App***************************************************** -->

<ng-container *ngIf="isHybrid">
  <section *ngIf="selectedApprover">
    <div *ngIf="config.countryName !== 'Tanzania'">
      <div class="hybrid-divider-label">Approver</div>
      <div class="hybrid-to-section padding-0 mb-32">
        <div class="">
          <p class="approver-title">Approver:</p>
          <p class="approver-description">
            {{ selectedApprover.description }} -
            {{ selectedApprover.hierarchyEnabled ? 'With hierarchy' : 'Without hierarchy' }}
          </p>
        </div>
        <hr class="light-solid mb-0" *ngIf="selectedApprover.remarks" />
        <div class="" *ngIf="selectedApprover.remarks">
          <p class="approver-title pt-0">Note to Approver:</p>
          <p class="approver-description">{{ selectedApprover.remarks }}</p>
        </div>
      </div>
    </div>
    <div *ngIf="config.countryName === 'Tanzania'">
      <div class="hybrid-detail-block">
        <p class="hybrid-detail__heading">Approver</p>
        <p class="hybrid-detail__value">
          {{ selectedApprover.description }} -
          {{ selectedApprover.hierarchyEnabled ? 'With hierarchy' : 'Without hierarchy' }}
        </p>
      </div>
      <div class="hybrid-detail-block" *ngIf="selectedApprover.remarks || config.countryName === 'Tanzania'">
        <p class="hybrid-detail__heading">Note to Approver</p>
        <p class="hybrid-detail__value">{{ selectedApprover.remarks ? selectedApprover.remarks : '-- --' }}</p>
      </div>
    </div>
  </section>
</ng-container>
