import { Component, NgZone, OnInit } from '@angular/core';
import { DataSharingService } from '../../core/data-sharing/data-sharing.service';
import { ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import last from 'lodash/last';
import { environment } from '../../../environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { LoaderService } from '../loader/loader.service';
import { RouterStateService } from '../../services/route-state/route-state.service';
import { TearDownBridge } from '../../native/lib/store/actions/mAuthAction';
import { Store as Ngxs } from '@ngxs/store';
import { Subject, takeUntil } from 'rxjs';
import { SignOutService } from '@core/sign-out/sign-out.service';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent implements OnInit {
  config = environment.config;
  contactNumber = environment.config.localOtpCustomerCare;
  mailId = environment.config.localEmailCustomerCare;
  isHybrid: boolean;
  errorOccured: boolean;
  isDashboard: boolean;
  isLoggedIn;
  errorMessage;
  routeHistory;
  isWarning = false;
  hybNumbers = [];
  isMultiContactNumber = false;
  isdashboardError = false;
  currentUrl: string = '';
  errorReason;
  private destroy$ = new Subject<void>();

  constructor(
    private dataSharingService: DataSharingService,
    private ref: ChangeDetectorRef,
    private store: Store<{ appReducer }>,
    private ngZone: NgZone,
    private router: Router,
    private routeState: RouterStateService,
    private ngxsStore: Ngxs,
    private loaderService: LoaderService,
    public signOutService: SignOutService
  ) {
    // this.currentUrl = this.routeState.getCurrentUrl();
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentUrl = val.url;
      }
    });
  }

  ngOnInit() {
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      if (value.isSmeMode) {
        this.contactNumber = this.config.localSMEcontactNumber;
        this.mailId = this.config.localSMEEmail;
      }
      if (Object.prototype.hasOwnProperty.call(value, 'isMobileActive')) {
        this.isHybrid = value.isMobileActive.isHybrid;
        this.isDashboard = value.isMobileActive.dashboard;
      }
      if (this.contactNumber.includes('/')) {
        this.isMultiContactNumber = true;
        this.hybNumbers = this.contactNumber.split('/');
      }
    });

    // this.dataSharingService.getErrorModal().subscribe((value) => {
    //   this.errorOccured = value;
    //   this.ref.detectChanges();
    //   if (this.config.allowedHybDashboard) {
    //     if (this.isHybrid) {
    //       if (
    //         this.routeState.getCurrentUrl() === '/dashboard' ||
    //         this.routeState.getCurrentUrl() === '/mobile'
    //       ) {
    //         this.isdashboardError = true;
    //         this.loaderService.hide();
    //       }
    //     }
    //   }
    // });
    this.dataSharingService.getErrorModal().subscribe((value) => {
      this.ngZone.run(() => {
        this.errorOccured = value;
        this.ref.detectChanges();
        if (this.config.allowedHybDashboard) {
          if (this.isHybrid) {
            if (this.currentUrl === '/dashboard' || this.currentUrl === '/mobile') {
              this.isdashboardError = true;
              this.loaderService.hide();
            } else {
              this.isdashboardError = false;
            }
          }
        }
      });
    });

    this.dataSharingService
      .getErrorMessage()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.ngZone.run(() => {
          if (value.type === 'warning') {
            this.errorMessage = value.warningMessage;
            this.isWarning = true;
          } else {
            this.errorMessage = value.warningMessage;
            this.isWarning = false;
          }
          this.dataSharingService.setHybridHeaderOptions(false);
          if (this.config.allowedHybDashboard) {
            if (this.isHybrid) {
              if (this.currentUrl === '/dashboard' || this.currentUrl === '/mobile') {
                this.isdashboardError = true;
                this.loaderService.hide();
              } else {
                this.isdashboardError = false;
              }
            }
          }

          this.loaderService.hide();
        });
      });

    this.dataSharingService.getErrorReason().subscribe((value) => {
      this.ngZone.run(() => {
        this.errorReason = value;
        this.ref.detectChanges();
      });
    });

    this.dataSharingService.setHeadPagination({
      mainTitle: `Error`,
    });
  }

  closeErrorModal() {
    this.dataSharingService.setErrorMessage('');
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      this.isLoggedIn = value.loggedIn;
      if (value.isSmeMode) {
        this.contactNumber = this.config.localSMEcontactNumber;
        this.mailId = this.config.localSMEEmail;
      }
      if (this.isHybrid && !this.isDashboard) {
        this.ngxsStore.dispatch(new TearDownBridge({ isError: true }));
      }
      if (this.isHybrid && this.isDashboard) {
        this.router.navigate(['/dashboard']);
      }
    });

    this.dataSharingService.setErrorModal(false);
    this.dataSharingService.setErrorMessage(null);
    this.dataSharingService.setErrorReason(null);

    this.loaderService.hide();
    this.ngZone.run(() => {
      if (this.isLoggedIn) {
        this.routeHistory = this.routeState.getHistory();
        const prevUrl = last(this.routeHistory);
        if (this.navigateErrorModal(prevUrl) === 'samepage') {
          // do nothing
        } else if (this.navigateErrorModal(prevUrl) === 'login') {
          this.router.navigate(['/login']);
        } else {
          this.router.navigate(['/dashboard']);
        }
      } else {
        this.router.navigate(['/login']);
      }
    });
  }
  navigateErrorModal(prevUrl) {
    let result = '';
    const toSame = [
      '/sendMoney/cancelInstantMoney/list',
      '/review',
      '/payments/biller/details',
      '/recipients-management/biller/details-biller',
      '/approvals',
      '/transaction-management',
      '/payments/beneficiary/once-off-beneficiary-details',
      '/registration/securityQuestions',
      '/registration/verifyUser',
      '/registration/createPassword',
      '/profileAndSettings/username',
      '/otp',
      '/forcedChange/securityQuestions',
    ];
    const toLogin = ['/forcedChange', '/forgotPassword', '/registration'];
    toSame.forEach((element) => {
      if (element === prevUrl) {
        result = 'samepage';
      }
    });
    toLogin.forEach((element) => {
      if (element === prevUrl) {
        result = 'login';
      }
    });
    return result;
  }
  ngOnDestroy(): void {
    this.loaderService.hide();
    this.destroy$.next();
    this.destroy$.complete();
  }

  signOut() {
    this.dataSharingService.setErrorModal(false);
    this.dataSharingService.setErrorMessage(null);
    this.dataSharingService.setErrorReason(null);
    this.signOutService.signout();
  }
}
