import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BridgeService {
sessionToken$: Observable<string | null>;

private sessionToken = new BehaviorSubject<string | null>(null);
// private sessionContext = new BehaviorSubject<BridgeContext | null>(null);
private hasToken = new BehaviorSubject<boolean>(false);
// private hasContext = new BehaviorSubject<boolean>(false);
private bridgeIsSetup = false;
private nativeWindow = window as any;
phoneNumber: any;
nonZeroPhoneNumber: any;
protected nativeData = new Subject<any>();
nativeData$ = this.nativeData.asObservable();
private otpSubject = new Subject<string>();
otp$: Observable<string> = this.otpSubject.asObservable();

constructor(private ngZone: NgZone) {
  this.sessionToken$ = this.sessionToken.asObservable();
}

registerBridge(): void {
  if (!this.bridgeIsSetup) {
    if (this.setupBridge()) {
      this.bridgeIsSetup = true;
      this.requestNewToken();
    }
  }
}

requestNewToken(displayNativeProgressSpinner = false): void {
  const shouldShowProgress = {
    displayNativeProgress: displayNativeProgressSpinner,
  };
  this.sessionToken.next(null);

  if (this.nativeWindow.webkit && this.nativeWindow.webkit.messageHandlers.SessionBridge) {
    this.nativeWindow.webkit.messageHandlers.SessionBridge.postMessage(JSON.stringify(shouldShowProgress));
  } else if (this.nativeWindow.SessionBridge) {
    this.nativeWindow.SessionBridge.postMessage(JSON.stringify(shouldShowProgress));
  }
}

openContactPicker(data: any, mobileCountryCode: string) {
  // const postMessage = {actionType: 'contact',metadata: {type: data.type}};
  const messageAction = { type: data.type };
  if (this.nativeWindow.webkit && this.nativeWindow.webkit.messageHandlers.SelectContactBridge) {
    this.nativeWindow.webkit.messageHandlers.SelectContactBridge.postMessage(JSON.stringify(messageAction));
  } else if (this.nativeWindow.SelectContactBridge) {
    this.nativeWindow.SelectContactBridge.postMessage(JSON.stringify(messageAction));
  }
  this.setNativeDataCallback(mobileCountryCode);
}
// On my test, I called this function on the ngOnInit of the OTP Component
readSmsBridge(): void {
  if (this.nativeWindow.webkit && this.nativeWindow.webkit.messageHandlers.ReadSmsBridge) {
    // IB - iOS Bridge / Use the name of the Bridge provided by Moses
    this.nativeWindow.webkit.messageHandlers.ReadSmsBridge.postMessage(null);
  } else if (this.nativeWindow.ReadSmsBridge) {
    // IB - Android Bridge / Use ReadSmsBridge for Android
    this.nativeWindow.ReadSmsBridge.postMessage(null);
  }
  this.setReadSmsCallback();
}

setReadSmsCallback(): void {
  // IB - allback for Android
  this.nativeWindow.readSmsCallback = (data: string) => {
    this.ngZone.run(() => {
      // Send data back to screen
      // data is the retrieved OTP on Android
      this.otpSubject.next(data);
    });
  };
}

protected setNativeDataCallback(mobileCountryCode: string) {
  this.nativeWindow.nativeDataCallback = (data: string) => {
    this.ngZone.run(() => {
      // this.nativeData.next(data);
      const number = this.handleContactPickerData(data, mobileCountryCode);
      this.nativeData.next(number);
    });
  };
}

private handleContactPickerData(contactDetails: any, mobileCountryCode: string): any[] {
  let contact: any;
  let number;
  let countryCode;
  let mobileNumber = contactDetails?.data?.value;

  if (!mobileNumber) {
    return [''];
  }
  //number starting with Zero
  if (mobileNumber.startsWith('0')) {
    this.phoneNumber = mobileNumber.slice(1); //78 789 3734
    number = this.phoneNumber.replace(/\s|-/g, ''); //787893734
    contact = [mobileCountryCode, number];
  }
  //number starting with +
  else if (mobileNumber.startsWith('+')) {
    this.nonZeroPhoneNumber = mobileNumber.trim().split(/\s+/);
    //number starting with country code and plus without space
    if (this.nonZeroPhoneNumber.length === 1) {
      const mobileCountryCodeWithPlus = mobileCountryCode;
      const countryCodeRegex = new RegExp(`^${mobileCountryCodeWithPlus.replace('+', '\\+')}`);
      if (countryCodeRegex.test(mobileNumber)) {
        countryCode = mobileCountryCodeWithPlus;
        const number = mobileNumber.replace(countryCodeRegex, '').replace(/[\s-]/g, '');
        contact = [countryCode, number];
      }
      //number starting with plus with different country code
      else {
        number = mobileNumber.replace(/[\s-]/g, '');
        contact = ['', number];
      }
    }
    //number starting with plus with spaces
    else {
      countryCode = this.nonZeroPhoneNumber[0]; // REWRITE " " IF NOT SPACE COUNTRYCODE SHOULD BE EMPTY
      number = this.nonZeroPhoneNumber.slice(1).join('').replace(/-/g, ''); //+27787893734
      contact = [countryCode, number];
    }
  }
  //numbers without 0 and plus
  else {
    if (!mobileCountryCode.startsWith('+')) {
      mobileCountryCode = `+${mobileCountryCode}`; //+27787893734
    }
    //number starting with country code
    const countryCodeRegex = new RegExp(`^${mobileCountryCode.replace('+', '')}`);
    if (countryCodeRegex.test(mobileNumber)) {
      countryCode = mobileCountryCode;
      const number = mobileNumber.replace(countryCodeRegex, '').replace(/[\s-]/g, '');
      contact = [countryCode, number];
    }
    // number without 0 , + or country code
    else {
      countryCode = mobileCountryCode;
      number = mobileNumber.replace(/[\s-]/g, '');
      contact = [countryCode, number];
    }
  }
  return contact;
}

dialerBridge(numb) {
  const numberObj = {
    actionType: 'dialer',
    metadata: {
      number: numb,
    },
  };
  if (this.nativeWindow.webkit && this.nativeWindow.webkit.messageHandlers.DeepLinkingBridge) {
    this.nativeWindow.webkit.messageHandlers.DeepLinkingBridge.postMessage(JSON.stringify(numberObj));
  } else if (this.nativeWindow.DeepLinkingBridge) {
    this.nativeWindow.DeepLinkingBridge.postMessage(JSON.stringify(numberObj));
  } else {
    this.nativeWindow.open(`tel:${numb}`, '_self');
  }
}

notificationBridge(message) {
  const notification = {
    notificationType: 'success',
    metadata: {
      message: JSON.stringify(message),
    },
  };
  if (this.nativeWindow.webkit && this.nativeWindow.webkit.messageHandlers.NotificationBridge) {
    this.nativeWindow.webkit.messageHandlers.NotificationBridge.postMessage(JSON.stringify(notification));
  } else if (this.nativeWindow.NotificationBridge) {
    this.nativeWindow.NotificationBridge.postMessage(JSON.stringify(notification));
  }
}

performDeepLink(actionType, link) {
  const deepLinkAction = {
    actionType: actionType,
    metadata: {
      url: link,
    },
  };
  if (this.nativeWindow.webkit && this.nativeWindow.webkit.messageHandlers.DeepLinkingBridge) {
    this.nativeWindow.webkit.messageHandlers.DeepLinkingBridge.postMessage(JSON.stringify(deepLinkAction));
  } else if (this.nativeWindow.DeepLinkingBridge) {
    this.nativeWindow.DeepLinkingBridge.postMessage(JSON.stringify(deepLinkAction));
  }
}
simpleDeeplink(actionType, token) {
  const deepLinkAction = {
    actionType: actionType,
    metadata: {
      tokenKey: token,
    },
  };

  if (this.nativeWindow.webkit && this.nativeWindow.webkit.messageHandlers.DeepLinkingBridge) {
    this.nativeWindow.webkit.messageHandlers.DeepLinkingBridge.postMessage(JSON.stringify(deepLinkAction));
  } else if (this.nativeWindow.DeepLinkingBridge) {
    this.nativeWindow.DeepLinkingBridge.postMessage(JSON.stringify(deepLinkAction));
  }
}
tearDownBridge(): void {
  if (this.nativeWindow.webkit && this.nativeWindow.webkit.messageHandlers.TearDownBridge) {
    this.nativeWindow.webkit.messageHandlers.TearDownBridge.postMessage(null);
  } else if (this.nativeWindow.TearDownBridge) {
    this.nativeWindow.TearDownBridge.postMessage(null);
  }
}

private setupBridge(): boolean {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;

  this.nativeWindow.getAccessTokenCallback = function getAccessToken(accessToken): boolean {
    self.sessionToken.next(accessToken.token);
    self.hasToken.next(true);
    return true;
  };

  // this.nativeWindow.getContextCallback = function getContextCallback(context): boolean {
  //   self.sessionContext.next(context);
  //   self.hasContext.next(true);
  //   return true;
  // };

  return true;
}
}

