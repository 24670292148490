import { createReducer, on } from '@ngrx/store';
import { setGepgBillData, setGepgFormData, setGepgReceiptData } from '../actions/gepg.action';
import { gepgState } from '../state/gepg.state';

const setGepgBillDetailsInitialData = {};
const setGepgFormDataInitialState = {};
const setGepgReeiptDataInitialState = {};

const gepgData: gepgState = {
  gepgBillData: setGepgBillDetailsInitialData,
  gepgFormData: setGepgFormDataInitialState,
  gepgReceiptData:setGepgReeiptDataInitialState
};

export const GepgReducer = createReducer(
  gepgData,
  on(setGepgBillData, (state, { gepgBillData }) => ({
    ...state,
    gepgBillData,
  })),
  on(setGepgFormData, (state, { gepgFormData }) => ({
    ...state,
    gepgFormData,
  })),
  on(setGepgReceiptData, (state, { gepgReceiptData }) => ({
    ...state,
    gepgReceiptData,
  })),
);
