import { DataSharingService } from './../data-sharing/data-sharing.service';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UuidGeneratorService } from '../UUID-generator/uuid-generator.service';
import { TransactionService } from '../transaction-service/transaction.service';
import { BankingMetadataService } from '../banking-metadata/banking-metadata.service';
import { environment } from '../../../environments/environment';
import map from 'lodash/map';
import cloneDeep from 'lodash/cloneDeep';
// import { BOPPuproseCode } from '../../components/payments/international-payment/constants/purposeCode.constants';
import { DateUtil } from '../utility-classes/date.util';

@Injectable({
  providedIn: 'root',
})
export class PaymentUtilitiesService {
  config = environment.config;
  modeOfCommunication;
  defaultModeOfCommunication;
  authenticateResponse;
  // formatPurposeCodeData;
  purposeCodesFormatted;
  constructor(
    private store: Store<any>,
    private dataservice: DataSharingService,
    private bankingMetadataService: BankingMetadataService,
    private transactionService: TransactionService,
    private uuidGeneratorService: UuidGeneratorService
  ) {
    this.modeOfCommunication = this.config.modeOfCommunication;
    this.defaultModeOfCommunication = this.config.defaultModeOfCommunication;
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      if (value.authenticateResponse) {
        this.authenticateResponse = value.authenticateResponse;
      }
    });
  }

  public checkPublicHoliday(publicHolidays, selectedDate): boolean {
    const day = DateUtil.getDay(selectedDate);
    const isWeekend = day === 6 || day === 0;
    if (isWeekend || this.isPublicHoliday(publicHolidays, selectedDate)) {
      return true;
    } else {
      return false;
    }
  }

  public checkforPilotCustomer(val) {
    const authData = this.authenticateResponse.keyValueMetadata;
    let isPilotUser = false;
    for (let i = 0; i < authData.length; i++) {
      if (authData[i].key === 'USER_ALLOWED_TXN_TYPES' && authData[i].value.match(val)) {
        isPilotUser = true;
        break;
      }
    }
    return isPilotUser;
  }

  public matchTransactionId(data: any, reciept: any) {
    return reciept[0]; //Don't remove this line
    for (let k = 0; k < reciept.length; k++) {
      if (data.transactionId === reciept[k].transactionId) {
        return reciept[k];
      }
    }
  }

  public getExchangeRates(exchangeCurrency: any, localCurrency: any) {
    let requiredRate;
    const ExchangeCurrency = exchangeCurrency;
    const payload = {
      baseCurrency: ExchangeCurrency,
    };

    this.bankingMetadataService.getExchangeRates(payload).subscribe((response) => {
      const exchangeRates = response;
      exchangeRates.forEach((value) => {
        if (value.toCurrency === localCurrency) {
          if (value.toCurrency === value.fixedCurrency) {
            requiredRate = 1 / value.rate;
            requiredRate = requiredRate.toFixed(5);
            return requiredRate;
          } else {
            return value.rate;
          }
        }
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public chargeCalculator(amount: any, index: any, dataModal: any) {
    /* var appliedCharge, chargeForView, allCharges = [];
  var chargeList = dataModal.benificiaryTxnCharge.beneficiary.charges;
  var iteratLength = chargeList.length;

  for (var n = 0; n < iteratLength; n++) {
    if (amount <= chargeList[n].upperLimit && amount >= chargeList[n].lowerLimit &&
      chargeList[n].upperLimit) {
      appliedCharge = chargeList[n].charge;
    } else if (!chargeList[n].upperLimit && amount >= chargeList[n].lowerLimit) {
      appliedCharge = chargeList[n].charge;
    } else {
      $log.log('Amount does not fall in any charge limit');
    }
  }
  chargeForView = appliedCharge;
  if (parseFloat(amount) === 0) {
    appliedCharge = 0;
    chargeForView = chargeList[0].charge;
  }
  allCharges.appliedCharge = appliedCharge;
  allCharges.chargeForView = chargeForView;
  return allCharges; */
  }

  public getApplicableRate(exchangeRates, currency): string {
    let requiredRate;
    for (let i = 0; i < exchangeRates.length; i++) {
      if (exchangeRates[i].toCurrency === currency) {
        if (exchangeRates[i].toCurrency === exchangeRates[i].fixedCurrency) {
          requiredRate = (1 / exchangeRates[i].rate).toFixed(5);
        } else {
          requiredRate = exchangeRates[i].rate.toFixed(2);
        }
      }
    }
    return requiredRate;
  }

  public changeObjAllProperty(data: any, value: any) {
    for (const prop in data) {
      if (data.hasOwnProperty(prop)) {
        data[prop] = value;
      }
    }
  }

  public iteratorModifyArray(data: any, modalData: any, currency: any) {
    const dataToReturn = { modifyArray: null, modalData: null };
    modalData.addressFlag = [];

    data = map(data, (value: any, ind: any) => {
      modalData.addressFlag[ind] = {
        email: value.emailAddress,
        mobile: value.phoneNumber,
      };

      const dataObj = {
        amount: {
          currency,
          amount: value.isFlowEditSchedule ? value.scheduledAmount.amount.toString() : '',
        },

        selectedBranch: value.route.branch,
        selectedBank: value.route.branch.bank,
        routeNetworkType: value.route.networkType,

        yourReference: value.customerReference,
        theirReference: value.recipientReference,

        paymentProofs: this.modeOfCommunication,
        selectedPaymentProof:
          value.isFlowEditSchedule && value.selectedRadio
            ? value.selectedRadio
            : this.defaultModeOfCommunication,
      };

      if (!value.isFlowEditSchedule) {
        const obj = {
          ...value.paymentConfirmation,
          confirmationType: 'None',
          countryCode: '',
          address: '',
        };

        value = {
          ...value,
          paymentConfirmation: obj,
        };
      } else {
        value.paymentConfirmation = JSON.parse(JSON.stringify(value.paymentConfirmation));

        if (!value.paymentConfirmation.address) {
          value.paymentConfirmation.address = '';
        }
        if (!value.paymentConfirmation.confirmationType) {
          value.paymentConfirmation.confirmationType = 'None';
        }

        if (value.paymentConfirmation.confirmationType === 'SMS') {
          const splitAddress = value.paymentConfirmation.address.split('-');
          value.paymentConfirmation.countryCode = {
            code: splitAddress[0],
          };
          value.paymentConfirmation.address = splitAddress[1];
        }
      }

      value = {
        ...value,
        ...dataObj,
      };

      return value;
    });

    modalData.singlePayeeSelected = data.length === 1;
    dataToReturn.modifyArray = data;
    dataToReturn.modalData = modalData;
    return dataToReturn;
  }

  public getAddressType(value: any) {
    value.address = value.address || '';
    if (value.confirmationType === 'SMS') {
      const splitAddress = value.address.split('-');
      value.countryCode = {
        code: splitAddress[0],
      };
      value.address = splitAddress[1];
    }
  }

  removeObjKey(value: any, keyToRemove: any) {
    const keyToRemoveLen = keyToRemove.length;
    for (let i = 0; i < keyToRemoveLen; i++) {
      delete value[keyToRemove[i]];
    }
    return value;
  }

  public benificiaryPaymentReviewData(dataModal: any, currentFlow: any) {
    const keyToRemove = [
      'paymentPriority',
      'amount',
      'recentPayment',
      'recentPayments',
      'theRadio',
      'beneLetters',
      'pastDetails',
      'networkTypes',
      'selectedRoute',
      'hideAddressField',
      'showACHToRTGChangeNote',
      'isWithinBank',
      'initiallyACH',
      'routeObjectACH',
      'routeObjectNRT',
      'routeObjectRTG',
      'showMultiNotificationSms',
      'showMultiNotificationEmail',
      'showACHToRTGAmountError',
      'showAddressField',
      'hideNetworkTypes',
      'selectedBank',
      'selectedBranch',
      'branches',
      'verifyError',
      'routeNetworkType',
      'selectedPaymentProof',
      'theirReference',
      'yourReference',
      'countryCode',
      'headerDetails',
      'paymentProofs',
      'moneyTransferTaxCode',
      'taxCodeMessage',
    ];
    const keyToRemovePayment = ['countryCode', 'address', 'email', 'confirmationType', 'mobileNumber'];

    const dataForNextPage = {
      currentFlow,
      paymentModel: dataModal,
      account: dataModal.selectedFromAccount,
      transactions: {
        beneficiaryPayments: [],
      },
      financialRule: null,
    };

    let objectCopy = cloneDeep(dataModal.selectedUserDetails);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    objectCopy = map(objectCopy, (dataObj: any, dataInd: any) => {
      dataObj.customerReference = dataObj.yourReference;
      dataObj.recipientReference = dataObj.theirReference;

      const thisAmount = dataObj.amount;
      dataObj = this.removeObjKey(dataObj, keyToRemove);

      if (!dataObj.address) {
        dataObj.address = {
          addressLine1: null,
        };
      }

      dataObj.paymentConfirmation = this.removeObjKey(dataObj.paymentConfirmation, keyToRemovePayment);

      let oftenScheduleName;
      if (dataModal.oftenSchedule && dataModal.oftenSchedule.value) {
        if (
          dataModal.oftenSchedule &&
          (dataModal.oftenSchedule.value.toUpperCase() === 'JUST ONCE' ||
            dataModal.oftenSchedule.value.toUpperCase() === 'SINGLE')
        ) {
          oftenScheduleName = 'SINGLE';
        } else {
          oftenScheduleName = dataModal.oftenSchedule.value.toUpperCase();
        }
      } else {
        oftenScheduleName = 'SINGLE';
      }

      dataForNextPage.transactions.beneficiaryPayments[dataInd] = {
        beneficiary: dataObj,
        paymentNetworkSwitch: null,

        futureDatedInstruction: {
          repeatInterval: oftenScheduleName,
          repeatNumber: parseInt(dataModal.numberOfPayments, 10) || 1,
          fromDate: dataModal.scheduleDate || dataModal.currentDate,
        },
        transactionId: this.uuidGeneratorService.getUUID(),
        amount: thisAmount,
      };

      if (dataModal.module === 'editSchedule') {
        dataForNextPage.transactions.beneficiaryPayments[dataInd].recentPayments =
          dataModal.selectedUserDetails[0].recentPayments;
      }

      if (
        this.config.countryName === 'Zimbabwe' &&
        dataModal.selectedUserDetails[dataInd].moneyTransferTaxCode
      ) {
        dataForNextPage.transactions.beneficiaryPayments[dataInd].moneyTransferTaxCode =
          dataModal.selectedUserDetails[dataInd].moneyTransferTaxCode;
      }
    });

    return dataForNextPage;
  }

  public getRecipientBankDetails(data) {
    const bankDetails = {
      networkType: '',
      branchName: '',
      code: '',
      bankName: '',
    };
    if (data && data.route) {
      bankDetails.networkType = data.route.networkType;
      if (data.route.branch) {
        bankDetails.branchName = data.route.branch.name;
        bankDetails.code = data.route.branch.code;
        if (data.route.branch.bank) {
          bankDetails.bankName = data.route.branch.bank.name;
        }
      }
    }
    return bankDetails;
  }

  public filtered(res: any, data: any, index: any) {
    if (res.statusText === 'OK') {
      const response = res.body;
      const filteredPayData = response.recipientPayments.filter((value) => {
        return parseFloat(value.recipientId) === data[index].recipientId;
      });
      return filteredPayData;
    }
  }

  public fetchPreviousPaymentDetails(data, module) {
    const pastPaymentsRequest = {
      recipientPayments: [],
    };
    data.forEach((value) => {
      const previousPayReq = {
        recipientType: module,
        recipientId: value.recipientId,
      };
      pastPaymentsRequest.recipientPayments.push(previousPayReq);
    });
    return pastPaymentsRequest;
  }

  public returnTxnCharges(/* data:any */) {
    const txnCharges = {};
    /* txnCharges.biller = this.getIndivisibleTxnCharges(
      data,
      "BILLER_TNX_CHARGE"
    );
    txnCharges.beneficiary = this.getIndivisibleTxnCharges(
      data,
      "OTHER_BANK_TNX_CHARGE"
    ); */
    return txnCharges;
  }

  public switchNetworkType(mdObject, newAmountValue, index) {
    const userDetails = (mdObject.selectedUserDetails && mdObject.selectedUserDetails[index]) || mdObject;
    // const userDetails = cloneDeep(userDetailsCopy);

    // const userDetailsCopy = (mdObject.selectedUserDetails && mdObject.selectedUserDetails[index]) || mdObject;
    // const userDetails = cloneDeep(userDetailsCopy);

    // Ghana : false
    if (this.config.onlyShowACHToRTGChangeNote) {
      // only show ACH to RTG change message as the validation will be handled at backend
      if (parseFloat(newAmountValue) >= this.config.amountLimitForAchToRtg) {
        userDetails.showACHToRTGChangeNote = true;
        userDetails.showAddressField = true;
        userDetails.hideNetworkTypes = true;
        if (userDetails.networkTypes && userDetails.networkTypes.length > 1) {
          // to display networkType as RTG when it is available and amount exceeds the limit
          userDetails.selectedRoute = userDetails.routeObjectRTG;
          userDetails.initiallyACH = true;
        }
      } else {
        userDetails.showACHToRTGChangeNote = false;
        userDetails.showAddressField = false;
        userDetails.hideNetworkTypes = false;
        // change networkType to ACH only if it was ACH initially & changed to RTG or ACH is selected explicitly
        if (
          userDetails.initiallyACH ||
          (userDetails.selectedRoute.networkType && userDetails.selectedRoute.networkType !== 'RTG')
        ) {
          userDetails.selectedRoute = userDetails.routeObjectACH;
          userDetails.initiallyACH = false;
        }
        // set address to null only if the networkType is ACH
        if (
          userDetails.selectedRoute &&
          userDetails.selectedRoute.networkType &&
          userDetails.selectedRoute.networkType === 'ACH'
        ) {
          if (userDetails.address) {
            userDetails.address.addressLine1 = null;
          } else {
            userDetails.beneficiaryAddress = null;
          }
        }
      }
    }

    return mdObject;
  }

  public getTransactionCharges() {
    // if (DataCache.returnData('TransactionCharges')) {
    //   return $q.when(DataCache.returnData('TransactionCharges'));
    // } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return this.transactionService.getTransactionCharges().subscribe((response) => {
      this.returnTxnCharges(/* response.data.setting */);
    });
    // }
  }

  public checkIfScheduledPaymentsIsAllowedForTransactionType(transactionType: any) {
    return (
      this.config.transactionTypesForScheduledPayments &&
      this.config.transactionTypesForScheduledPayments
        .map((types: any) => types.type)
        .includes(transactionType)
    );
  }
  public formatPurposeCodes(categories) {
    const purposeCodesFormatted = {};
    const purposeCodes = categories
      .map((category: any) => {
        const codeArr = category.code.split('/');
        const newCategory = {
          ...category,
          ...{
            purposeCode: codeArr[0],
            subPurposeCode: codeArr[1],
          },
        };
        if (!purposeCodesFormatted[codeArr[0]]) {
          purposeCodesFormatted[codeArr[0]] = [];
          purposeCodesFormatted[codeArr[0]].push(newCategory);
          return newCategory;
        }
        purposeCodesFormatted[codeArr[0]].push(newCategory);
        return undefined;
      })
      .filter((category: any) => {
        // return category && category.flow === 'OUT';
        return category;
      });
    return {
      purposeCodesFormatted,
      purposeCodes,
    };
  }
  public getPurposeCode(approvalData) {
    if (!approvalData.financialTransaction) {
      return {};
    }
    const formatPurposeCodeData = this.formatPurposeCodes(this.config.BOPPuproseCode);
    const purposeCodesFormatted = formatPurposeCodeData.purposeCodesFormatted;
    const financialTransaction = { ...approvalData.financialTransaction };
    if (
      financialTransaction.type === 'ONCE_OFF_INTERNATIONAL_PAYMENT' &&
      financialTransaction.reasonForTransaction
    ) {
      let purposeCode = financialTransaction.reasonForTransaction.key;
      const subPurposeCode = financialTransaction.subReasonForTransaction.key;
      const purposeCodes = purposeCodesFormatted[purposeCode] || [];
      let index = 0;
      if (purposeCodes.length > 1) {
        index = this.checkPurposeCode(purposeCodes, subPurposeCode);
        if (index === -1) {
          index = 0;
        }
      }
      purposeCode = purposeCodes[index];
      if (purposeCode) {
        return {
          purposeCode:
            this.config.countryName === 'Zambia'
              ? `${purposeCode.purposeCode} - ${purposeCode.section}`
              : `${purposeCode.purposeCode} - ${purposeCode.reason}`,
          subPurposeCode:
            this.config.countryName === 'Zambia'
              ? (purposeCode.subPurposeCode ? purposeCode.subPurposeCode + ' - ' : '') +
                purposeCode.description
              : (purposeCode.subPurposeCode
                  ? purposeCode.purposeCode + '/' + purposeCode.subPurposeCode + ' - '
                  : '') + purposeCode.subReason,
        };
      }
    }
  }
  public checkPurposeCode(purposeCodes, purposeCode): number {
    for (let i = 0; i < purposeCodes.length; i++) {
      if (purposeCode === purposeCodes[i].subPurposeCode) {
        return i;
      }
    }
    return -1;
  }
  private isPublicHoliday(publicHolidays, selectedDate): boolean {
    // country specific change
    const timeOffsetInHolidays = '+0300';
    let holidayDate;
    if (publicHolidays && publicHolidays.length) {
      for (let i = 0; i < publicHolidays.length; i++) {
        holidayDate = DateUtil.format(
          publicHolidays[i].date,
          DateUtil.ISO_YYYY_MM_DD_TIME,
          timeOffsetInHolidays
        );
        if (DateUtil.areSame(selectedDate, holidayDate, 'days')) {
          return true;
        }
      }
    }
  }
}
