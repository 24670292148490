import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstantService } from '../../../core/app-constant/app-constant.service';
import { DownloadService } from '../../../core/download-service/download.service';
import { ReceiptDetail } from './receipt-detail';
import { Amount } from '../../../core/data/amount';
import { McaResponseType } from '../../../core/data/mca-response-type';
import { environment } from '../../../../environments/environment';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { BankingMetadataService } from '@core/banking-metadata/banking-metadata.service';
import { takeUntil } from 'rxjs/operators';
import { DataSharingService } from '@core/data-sharing/data-sharing.service';

@Component({
  selector: 'sbg-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss'],
})
export class ReceiptComponent implements OnInit, OnDestroy {
  @Input() responseStatus: string;
  @Input() responseMessage: string;
  @Input() receiptHeaders: string[];
  @Input() receiptDetails: ReceiptDetail[];
  @Input() showAmount: boolean;
  @Input() amount: Amount;
  @Input() fromAccountData: ReceiptDetail[];
  @Input() showButton: boolean;
  @Input() textOfButton: string;
  @Input() linkOfButton: string;
  @Input() showApprovalRules: boolean;
  @Input() useEmittedNewTransferFunction = false;
  @Output() makeNewPayment = new EventEmitter<void>();

  @Input() showSaveButton: boolean;
  @Input() textOfSaveButton: string;
  @Output() savePayment = new EventEmitter<void>();
  @Input() showDownloadIcon = true;
  @Input() individual = 'individualReceipt';
  @Input() forAllReceipts = false;
  @Input() paymentType: string;
  @Input() saveBeneficiary: string;
  @Input() currentFlow: string;

  destroyed$ = new Subject<boolean>();
  bankLogo: string;
  config = environment.config;
  country = this.config.countryName;
  readonly MCA_RESPONSE_TYPE = McaResponseType;
  lastApprover = false;
  is_ipay = false;
  soleProp = false;
  moreThen5usd = false;
  selectedFinacialApproval: any;
  IsWalletReceipt: boolean;
  recepientname: string;
  iniciatedAmount: {
    amount: {
      amount: number;
      currency: string;
    };
  } = {
    amount: {
      amount: 0,
      currency: '',
    },
  };
  details: any;
  constructor(
    private store: Store<any>,
    private router: Router,
    private route: ActivatedRoute,
    private appConstant: AppConstantService,
    private downloadService: DownloadService,
    private bankingMetadata: BankingMetadataService,
    private dataSharingService: DataSharingService
  ) {}

  ngOnInit() {
    this.dataSharingService.getIsWalletReceipt().subscribe((data) => {
      this.IsWalletReceipt = data;
    });
    this.bankLogo = this.appConstant.BankLogo;
    if (this.config.trade === 'mvp1') {
      this.store
        .select('appReducer', 'loginReducer')
        .pipe(takeUntil(this.destroyed$))
        .subscribe((value) => {
          if (value.authenticateResponse.userProfile.hasOwnProperty('isSmeSoleProprietor')) {
            this.soleProp = true;
          }
        });
      if (this.soleProp) {
        this.store
          .select('appReducer', 'intPaymentReducer')
          .pipe(takeUntil(this.destroyed$))
          .subscribe((value) => {
            const requiredData = value?.preInitiateData?.transactionData?.baseBeneficiaryPayment?.amount;
            if (requiredData) {
              this.iniciatedAmount = {
                amount: {
                  amount: requiredData.amount,
                  currency: requiredData.currency,
                },
              };
              this.getValueValidations(this.iniciatedAmount);
            }
          });
      }
      this.store
        .select('appReducer', 'approvalReducer')
        .pipe(takeUntil(this.destroyed$))
        .subscribe((stateData) => {
          if (stateData) {
            this.is_ipay = stateData.selectedApprovalData.hasOwnProperty('financialTransaction');
            if (this.is_ipay) {
              this.selectedFinacialApproval = stateData.selectedApprovalData.financialTransaction;
              this.lastApprover = stateData.selectedApprovalData.financialTransaction.lastApprover;
              const requiredData = this.selectedFinacialApproval.amount;
              if (requiredData) {
                this.iniciatedAmount = {
                  amount: {
                    amount: requiredData.amount,
                    currency: requiredData.currency,
                  },
                };
                if (this.config.countryName !== 'Tanzania') {
                  this.getValueValidations(this.iniciatedAmount);
                }
              }
            }
          }
        });
    }
    this.recepientname = this.receiptDetails[0].value;
    this.details = this.receiptDetails;
    if (this.config.isTipsEnabled && this.config.isGepgandWallet && this.IsWalletReceipt) {
      this.details.shift();
    }
  }

  get getSelectorName() {
    return '.' + this.individual;
  }
  getValueValidations(iniciatedAmount) {
    this.bankingMetadata
      .getValueValidations(iniciatedAmount)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        if (data.body) {
          if (data.body.documentUploadRequired) {
            this.moreThen5usd = true;
          }
        }
      });
  }
  printReceipt() {
    this.downloadService.printReceipt(document, this.getSelectorName);
  }

  downloadReceipt() {
    this.downloadService.downloadReceipt(document, this.getSelectorName);
  }

  printAllReceipt() {
    this.downloadService.printAllReceipts();
  }

  downloadAllReceipt() {
    this.downloadService.downloadAllReceipts();
  }

  goToNewTransfer() {
    if (this.useEmittedNewTransferFunction) {
      this.makeNewPayment.emit();
    } else {
      this.router.navigate([this.linkOfButton], {
        relativeTo: this.route,
      });
    }
  }

  showAddBeneficiaryPopup() {
    this.savePayment.emit();
  }
  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
