import { createAction, props } from '@ngrx/store';
import { GepgBillData, GepgFormData, GepgReceiptData } from '../interfaces/gepg.model';
export const setGepgBillData = createAction(
  '[BillDetail Component] setGepgBillData',
  props<{ gepgBillData: GepgBillData }>()
);
export const setGepgFormData = createAction(
  '[SelfAccountTransfer Component] setGepgFormData',
  props<{ gepgFormData: GepgFormData }>()
);

export const setGepgReceiptData = createAction(
  '[SelfAccountTransfer Component] setGepgReceiptData',
  props<{ gepgReceiptData: GepgReceiptData }>()
);


